import React, { useEffect, useState } from "react";
import "./Scrolltop.css";
import { FiChevronUp } from "react-icons/fi";
function ScrollTop() {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 500) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <FiChevronUp  
      size={25}
      color="white"
        className={`scroll-to-top ${showButton ? "show fadeInRight" : ""}`}
        onClick={scrollToTop}
      />
    </div>
  );
}

export default ScrollTop;
