import React from "react";
import "./Footer.css";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="head-footer">
      <div className="footer-bg">
        <div className="flex-footer-1">
          <div className="left-h-footer">
            <img
              className="logo-footer"
              src={require("../../assets/img/1logo.png")}
              alt=""
            />

            <p className="footer-para">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Reprehenderit provident alias quos soluta ratione reiciendis culpa
              suscipit inventore blanditiis cumque
            </p>
          </div>
          <div>
            <div>
              <h1 className="footer-h1">Links</h1>
            </div>
            <div>
            <ul className="ul-footer">
              <Link to={"/Refund-Shipping-Policy"}>
                  <li>Refund Shipping Policy</li>
                </Link>
              <Link to={"/Privacy-Policy"}>
                  <li>Privacy Policy</li>
                </Link>
                <Link to={"/Terms-Conditions"}>
                  <li>Terms Conditions</li>
                </Link>
                <Link to={"/Contactus"}>
                  <li>Contact Us</li>
                </Link> 
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h1 className="footer-h1">Contact</h1>
            </div>
            <div>
              <ul className="ul-footer">
                <li className="email-li">Email Address</li>

                <li>contact@makemyown.in</li>

                <div
                  className="div-footer-icons
"
                >
                  <a
                    href="https://instagram.com/makemyown.in?igshid=MzRlODBiNWFlZA=="
                    target="_blank"
                  >
                    <div>
                      <AiOutlineInstagram
                        className="icon-footer"
                        size={20}
                        color="white"
                      />
                    </div>
                  </a>
                  {/* <div>
                    <AiFillYoutube
                      className="icon-footer"
                      size={20}
                      color="white"
                    />
                  </div> */}
                </div>
              </ul>
            </div>
          </div>
      
        </div>
      </div>
      <div>
        <h1 className=" text-copyright">
          © 2023 Delta Infox.All Rights Reserved
        </h1>
      </div>
    </div>
  );
};

export default Footer;
