import React, { useEffect, useMemo, useState } from "react";

import "./Checkout.css";

import { AiOutlineHeart } from "react-icons/ai";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeAllItems,
  removeFromBasket,
  selectBasketItems,
  selectBasketTotal,
} from "../../Components/Redux/basketSlice";
import { auth, db, storage } from "../../Components/Firebase/Firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Currency from "react-currency-formatter";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-hot-toast";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import frontImg from "../../assets/img/1logo.png";

const CheckOut = () => {
  const navigate = useNavigate();

  const location = useLocation  ();
  const totalAmount = location.state.total;


console.log(totalAmount)




  const items = useSelector(selectBasketItems);
  const [groupedItemsInBasket, setGroupedItemsInBasket] = useState([]);
  const dispatch = useDispatch();
  useMemo(() => {
    const groupedItems = items.reduce((results, item) => {
      (results[item.id] = results[item.id] || []).push(item);
      return results;
    }, {});

    setGroupedItemsInBasket(groupedItems);
  }, [items]);
  const basketTotal = useSelector(selectBasketTotal);

  const [userID] = useAuthState(auth);

  const [user, setUser] = useState();

  useEffect(() => {
    try {
      getDoc(doc(db, "user", userID?.uid)).then((docSnap) => {
        if (docSnap.exists()) {
          setUser(docSnap.data());
        } else {
          console.log("No Document Found");
        }
      });
    } catch {}
  }, [userID?.uid]);

  const uploadFile = () => {
    addDoc(collection(db, "orders"), {
      // email: user?.email,
      UserUid: user?.uid,
      Name: address?.name,
      Address: address?.address,
      Flat: address?.flat,
      Landmark: address?.landmark,
      Pincode: address?.pincode,
      State: address?.state,
      City: address?.city,
      Phone: address?.phone,
      ProductsQty: items.length,
      TotalAmount: basketTotal + 100,
      createdAt: serverTimestamp(),
    }).then((docRef1) => {
      for (let i = 0; i < items.length; i++) {
        // addDoc(collection(db, "orders", docRef?.id, "Product"), {
        //   ProductPrice: items[i]?.price,

        //   DeliveryPrice: 100,
        // });

        addDoc(collection(db, "orders", docRef1?.id, "Product"), {
          FrontTshirt: "",
          FrontImages: "",
          BackTshirt: "",
          BackImages: "",

          ProductPrice: items[i]?.price,
          TshirtColor: items[i]?.tcolor,
          TshirtSize: items[i]?.selectsize,
          DeliveryPrice: 100,
        }).then((docRef) => {
          if (items[i]?.imagecap) {
            fetch(items[i]?.imagecap)
              .then((response) => response.blob())
              .then((blob) => {
                const imageRef = ref(
                  storage,
                  `images/${user.uid}/${docRef1?.id}/${docRef?.id}${v4()}-${
                    items[i]?.imagecap.name
                  }`
                );
                uploadBytes(imageRef, blob).then((snapshot) => {
                  getDownloadURL(snapshot.ref).then((url) => {
                    const washingtonRef = doc(
                      db,
                      "orders",
                      docRef1?.id,
                      "Product",
                      docRef?.id
                    );
                    updateDoc(washingtonRef, {
                      FrontTshirt: url,
                    });
                  });

                  console.log("Image uploaded successfully");
                });
              });
          }

          if (items[i]?.imagecap2) {
            fetch(items[i]?.imagecap2)
              .then((response) => response.blob())
              .then((blob) => {
                const imageRef = ref(
                  storage,
                  `images/${user.uid}/${docRef1?.id}/${docRef?.id}${v4()}-${
                    items[i]?.imagecap2.name
                  }`
                );
                uploadBytes(imageRef, blob).then((snapshot) => {
                  getDownloadURL(snapshot.ref).then((url) => {
                    const washingtonRef = doc(
                      db,
                      "orders",
                      docRef1?.id,
                      "Product",
                      docRef?.id
                    );
                    updateDoc(washingtonRef, {
                      BackTshirt: url,
                    });
                  });

                  console.log("Image uploaded successfully");
                });
              });
          }

          const downloadAndUploadImages = async (imageUrls) => {
            const imagePromises = imageUrls.map(async (imageUrl) => {
              const response = await fetch(imageUrl);
              const blob = await response.blob();

              const imageRef = ref(
                storage,

                `images/${user.uid}/${docRef1?.id}/${docRef?.id}${v4()}-${
                  items[i]?.imageList
                }`
              );
              await uploadBytes(imageRef, blob);

              const url = await getDownloadURL(imageRef);
              return url;
            });

            const uploadedImageUrls = await Promise.all(imagePromises);
            return uploadedImageUrls;
          };

          const combinedImageUrl = items[i]?.imageList;

          if (combinedImageUrl && combinedImageUrl.length > 0) {
            downloadAndUploadImages(combinedImageUrl)
              .then((uploadedImageUrls) => {
                const washingtonRef = doc(
                  db,
                  "orders",
                  docRef1?.id,
                  "Product",
                  docRef?.id
                );
                updateDoc(washingtonRef, {
                  FrontImages: uploadedImageUrls.join(","),
                });

                console.log("Images uploaded successfully");
              })
              .catch((error) => {
                console.error("Error uploading images:", error);
              });
          }

          const downloadAndUploadImages2 = async (imageUrls) => {
            const imagePromises = imageUrls.map(async (imageUrl) => {
              const response = await fetch(imageUrl);
              const blob = await response.blob();

              const imageRef = ref(
                storage,

                `images/${user.uid}/${docRef1?.id}/${docRef?.id}${v4()}-${
                  items[i]?.imageList2
                }`
              );
              await uploadBytes(imageRef, blob);

              const url = await getDownloadURL(imageRef);
              return url;
            });

            const uploadedImageUrls = await Promise.all(imagePromises);
            return uploadedImageUrls;
          };

          const combinedImageUrl2 = items[i]?.imageList2;

          if (combinedImageUrl2 && combinedImageUrl2.length > 0) {
            downloadAndUploadImages2(combinedImageUrl2)
              .then((uploadedImageUrls) => {
                const washingtonRef = doc(
                  db,
                  "orders",
                  docRef1?.id,
                  "Product",
                  docRef?.id
                );
                updateDoc(washingtonRef, {
                  BackImages: uploadedImageUrls.join(","),
                });

                console.log("Images uploaded successfully");
              })
              .catch((error) => {
                console.error("Error uploading images:", error);
              });
          }
        });
      }

      toast.success("Order Confirmed");
      navigate("/");

      dispatch(removeAllItems());
    });
  };

  const guestsendform = () => {
    if (
      !email ||
      !name ||
      !pincode ||
      !addresss ||
      !city ||
      !state ||
      !flat ||
      !landmark ||
      !phone
    ) {
      toast.error("Please Fill All Fields");
    } else {
      addDoc(collection(db, "orders"), {
        ProductsQty: items.length,
        TotalAmount: basketTotal + 100,
        DeliveryPrice: 100,
        Name: name,
        Address: addresss,
        Flat: flat,
        Landmark: landmark,
        Pincode: pincode,
        State: state,
        City: city,
        Phone: phone,
        email: email,

        createdAt: serverTimestamp(),
      }).then((docRef1) => {
        for (let i = 0; i < items.length; i++) {
          addDoc(collection(db, "orders", docRef1?.id, "Product"), {
            FrontTshirt: "",
            FrontImages: "",
            BackTshirt: "",
            BackImages: "",

            ProductPrice: items[i]?.price,
            TshirtColor: items[i]?.tcolor,
            TshirtSize: items[i]?.selectsize,
            DeliveryPrice: 100,
          }).then((docRef) => {
            if (items[i]?.imagecap) {
              fetch(items[i]?.imagecap)
                .then((response) => response.blob())
                .then((blob) => {
                  const imageRef = ref(
                    storage,
                    `images/${docRef1?.id}/${docRef?.id}${v4()}-${
                      items[i]?.imagecap.name
                    }`
                  );
                  uploadBytes(imageRef, blob).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                      const washingtonRef = doc(
                        db,
                        "orders",
                        docRef1?.id,
                        "Product",
                        docRef?.id
                      );
                      updateDoc(washingtonRef, {
                        FrontTshirt: url,
                      });
                    });

                    console.log("Image uploaded successfully");
                  });
                });
            }

            if (items[i]?.imagecap2) {
              fetch(items[i]?.imagecap2)
                .then((response) => response.blob())
                .then((blob) => {
                  const imageRef = ref(
                    storage,
                    `images/${docRef1?.id}/${docRef?.id}${v4()}-${
                      items[i]?.imagecap2.name
                    }`
                  );
                  uploadBytes(imageRef, blob).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                      const washingtonRef = doc(
                        db,
                        "orders",
                        docRef1?.id,
                        "Product",
                        docRef?.id
                      );
                      updateDoc(washingtonRef, {
                        BackTshirt: url,
                      });
                    });

                    console.log("Image uploaded successfully");
                  });
                });
            }

            const downloadAndUploadImages = async (imageUrls) => {
              const imagePromises = imageUrls.map(async (imageUrl) => {
                const response = await fetch(imageUrl);
                const blob = await response.blob();

                const imageRef = ref(
                  storage,

                  `images/${docRef1?.id}/${docRef?.id}${v4()}-${
                    items[i]?.imageList
                  }`
                );
                await uploadBytes(imageRef, blob);

                const url = await getDownloadURL(imageRef);
                return url;
              });

              const uploadedImageUrls = await Promise.all(imagePromises);
              return uploadedImageUrls;
            };

            const combinedImageUrl = items[i]?.imageList;

            if (combinedImageUrl && combinedImageUrl.length > 0) {
              downloadAndUploadImages(combinedImageUrl)
                .then((uploadedImageUrls) => {
                  const washingtonRef = doc(
                    db,
                    "orders",
                    docRef1?.id,
                    "Product",
                    docRef?.id
                  );
                  updateDoc(washingtonRef, {
                    FrontImages: uploadedImageUrls.join(","),
                  });

                  console.log("Images uploaded successfully");
                })
                .catch((error) => {
                  console.error("Error uploading images:", error);
                });
            }

            const downloadAndUploadImages2 = async (imageUrls) => {
              const imagePromises = imageUrls.map(async (imageUrl) => {
                const response = await fetch(imageUrl);
                const blob = await response.blob();

                const imageRef = ref(
                  storage,

                  `images/${docRef1?.id}/${docRef?.id}${v4()}-${
                    items[i]?.imageList2
                  }`
                );
                await uploadBytes(imageRef, blob);

                const url = await getDownloadURL(imageRef);
                return url;
              });

              const uploadedImageUrls = await Promise.all(imagePromises);
              return uploadedImageUrls;
            };

            const combinedImageUrl2 = items[i]?.imageList2;

            if (combinedImageUrl2 && combinedImageUrl2.length > 0) {
              downloadAndUploadImages2(combinedImageUrl2)
                .then((uploadedImageUrls) => {
                  const washingtonRef = doc(
                    db,
                    "orders",
                    docRef1?.id,
                    "Product",
                    docRef?.id
                  );
                  updateDoc(washingtonRef, {
                    BackImages: uploadedImageUrls.join(","),
                  });

                  console.log("Images uploaded successfully");
                })
                .catch((error) => {
                  console.error("Error uploading images:", error);
                });
            }
          });
        }

        toast.success("Order Confirmed");
        navigate("/");

        dispatch(removeAllItems());
      });
    }
  };

  // const [frontimg,setfrontimg]=useState();
  // const selectfimg  = (img)=>{
  //   setfrontimg(img)
  // }
  // console.log(frontimg)

  const [address, setAddress] = useState([]);

  useEffect(() => {
    try {
      getDoc(doc(db, "user", userID?.uid)).then((docSnap) => {
        try {
          if (docSnap.exists()) {
            getDoc(
              doc(
                db,
                "user",
                userID?.uid,
                "Address",
                docSnap.data()?.defaultAddressid
              )
            ).then((docSnap) => {
              if (docSnap.exists()) {
                setAddress(docSnap.data());
              } else {
                console.log("No Document Found");
              }
            });
          } else {
            console.log("No Document Found");
          }
        } catch {}
      });
    } catch {}
  }, [userID?.uid]);

  const [name, setName] = useState();

  const [phone, setPhone] = useState();
  const [pincode, setPincode] = useState();
  const [addresss, setAddresss] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [flat, setFlat] = useState();
  const [landmark, setLandmark] = useState();

  return (
    <>
      <div className="header-cart-show">
        <img
          onClick={() => navigate("/")}
          src={require("../../assets/img/1logo.png")}
          style={{ width: "70px", cursor: "pointer" }}
          alt=""
        />

        <div className="top-cart-h2">
          {user ? (
            <>
              <span className="span-1-cart">Signed as</span>
              <span className="span-2-cart">{user?.phoneNumber}</span>
            </>
          ) : (
            <>
              <ul className="ul-nav2">
                <Link to={"/Login"}>
                  <li>Login</li>
                </Link>
              </ul>
            </>
          )}
        </div>
      </div>
      <hr />
      <div className="check-d-1">
        <div className="check-d-2">
          <div className="check-content-head-div">
            <div className="left-h-check">
              {/* <button onClick={handleSubmit}>submit</button> */}
              {user ? (
                <>
                  <div className="">
                    <h1 className="head-ach1">Address Book</h1>
                    <p className="p-acc-det">
                      Save all your addresses for a faster checkout experience.
                    </p>
                    <h1 className="name-add">User Name : {user?.Phone}</h1>
                    {address.length === 0 ? (
                      <>
                        <div className="address-adcheckout">
                          <h1
                            onClick={() => navigate("/Account")}
                            className="address-adcheckout-h1"
                          >
                            Add Address & Mark as default Address
                          </h1>
                        </div>
                      </>
                    ) : (
                      <>
                        <>
                          <div className="show-add">
                            <div className="def-add">
                              <h1 className="name-add">{address?.name}</h1>

                              {user?.defaultAddressid === address?.id ? (
                                <></>
                              ) : (
                                <></>
                              )}
                            </div>

                            <p className="address-p">{address?.email}</p>
                            <p className="address-p">{address?.address}</p>
                            <p className="address-p">
                              {address?.flat},{address?.address},
                              {address?.landmark}, {address?.pincode},
                              {address?.state}, {address?.city}
                            </p>
                            <h1 className="phone-no-ad">
                              Phone: <span>{address?.phone}</span>
                            </h1>
                            <button onClick={() => navigate("/Account")}>
                              View
                            </button>
                          </div>
                        </>
                      </>
                    )}

                    <div className="chec-out-btn-div">
                      <button
                        onClick={uploadFile}
                        // onClick={() => sendform2()}
                        className="check-out-btnn-cart1"
                      >
                     
                        Payment
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="log-btn-div-check">
                    <h1 className="check-h1">Enter your name and address:</h1>
                    <div className="form-check-address">
                      <div className="form-check-div2">
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                          <label
                            for="input"
                            alt="Name"
                            placeholder="Name"
                          ></label>
                        </div>

                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          <label
                            for="input"
                            alt="Email"
                            placeholder="Email"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setFlat(e.target.value)}
                            value={flat}
                          />
                          <label
                            for="input"
                            alt="Flat/House No"
                            placeholder="Address Line 1"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setAddresss(e.target.value)}
                            value={addresss}
                          />
                          <label
                            for="input"
                            alt="Address Line 1"
                            placeholder="Address Line 1"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setLandmark(e.target.value)}
                            value={landmark}
                          />
                          <label
                            for="input"
                            alt="Landmark"
                            placeholder="Landmark"
                          ></label>
                        </div>
                        <div className="d-f-c-8">
                          <div>
                            <input
                              className="input-form-check"
                              id="input"
                              type="text"
                              required
                              onChange={(e) => setPincode(e.target.value)}
                              value={pincode}
                            />
                            <label
                              for="input"
                              alt="Pin Code"
                              placeholder="Pin Code"
                            ></label>
                          </div>
                          <div>
                            <input
                              className="input-form-check"
                              id="input"
                              type="text"
                              required
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                            />
                            <label
                              for="input"
                              alt="City"
                              placeholder="City"
                            ></label>
                          </div>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                          />
                          <label
                            for="input"
                            alt="State"
                            placeholder="State"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <h1 className="check-h1">
                      What's your contact information?:
                    </h1>
                    <div className="form-check-address">
                      <div className="form-check-div2">
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                          />
                          <label
                            for="input"
                            alt="Phone Number"
                            placeholder="Phone Number"
                          ></label>
                        </div>
                        <div className="chec-out-btn-div">
                          <button
                            onClick={() => guestsendform()}
                            className="check-out-btnn-cart1"
                          >
                            Check Out
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <img style={{ width: "50px" }} src={frontImg} alt="" />

            <div className="right-h-cart1">
              <div className="heading-pay">
                <h1>Summary</h1>
       
              </div>
              <div className="head-pay-centent">
                <div className="d-f-car-5">
                  <h1>Subtotal  </h1>
                  <h1>
                    <Currency quantity={basketTotal} currency="INR" />
                  </h1>
                </div>
                <div className="d-f-car-5 border-b-c">
                  <h1>Estimated Delivery & Handling </h1>
                  <h1>₹100</h1>
                </div>
                <div className="d-f-car-5 border-b-c">
                  <h1>Total</h1>
                  <h1>
                    {" "}
                    <Currency quantity={totalAmount + 100} currency="INR" />
                  </h1>
                </div>
                {Object.entries(groupedItemsInBasket).map(([key, items]) => (
                  <div key={key}>
                    <div className="d-f-car-6 ">
                      <div>
                        <img
                          className="check-proimg"
                          src={items[0]?.imagecap}
                          alt=""
                        />
                        <img
                          className="check-proimg"
                          src={items[0]?.imagecap2}
                          alt=""
                        />

                          {/* <img
                            className="check-proimg"
                            src={items[0]?.imageList}
                            alt=""
                          />
                          <img
                            className="check-proimg"
                            src={items[0]?.imageList2}
                            alt=""
                          /> */}
                      </div>

                      <div className="cart-gap-1">
                        <div className="d-f-cart12 ">
                          <h1 className="product-name-cart">
                            {" "}
                            {items[0]?.name}
                          </h1>
                          <h1 className="product-name-cart">
                            ₹{items[0]?.price}
                          </h1>
                        </div>

                        {/* {items[0]?.imageList && (
                          <div>
                            {items[0].imageList.map((item, index) => (
                              <img
                                style={{ width: "50px" }}
                                key={index}
                                src={item}
                                alt={`Image ${index}`}
                              />
                            ))}
                          </div>
                        )} */}
                        {/* <img src={items[0]?.imageList} alt="" />
              <img src={items[0]?.imageList2} alt="" /> */}
                        {items[0]?.selectcolor ? (
                          <>
                            <div>
                              <h1 className="color-cart-p">
                                color/{items[0]?.selectcolor}
                              </h1>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="d-f-cart-3">
                          <h1 className="color-cart-p">
                            {" "}
                            Size {items[0]?.selectsize}
                          </h1>
                          <h1 className="color-cart-p">Qty {items.length} </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOut;
