import React from "react";
import "./Productcard.css";
import { useNavigate } from "react-router-dom";

const Productcard = () => {


const naviagte = useNavigate();

  return (
    <div className="flex-pro-1">
      <div className="pro-card " onClick={()=> naviagte('/ProductView')}>
        <img className="pro-card-img" src={require("../../assets/img/c1.jpg")} alt="" />
        <div className="card-content">
            <h1 className="brand-name-c">mmo</h1>
            <p className="card-p-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <h1 className="card-price">₹500</h1>
           <div className="cott-div">
            <h1 className="cot-h1">100% Cotton</h1>
           </div>

        </div>
      </div>
      <div className="pro-card">
        <img className="pro-card-img" src={require("../../assets/img/c2.jpeg")} alt="" />
        <div className="card-content">
            <h1 className="brand-name-c">mmo</h1>
            <p className="card-p-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <h1 className="card-price">₹500</h1>
           <div className="cott-div">
            <h1 className="cot-h1">100% Cotton</h1>
           </div>

        </div>
      </div>
      <div className="pro-card">
        <img className="pro-card-img" src={require("../../assets/img/c3.jpg")} alt="" />
        <div className="card-content">
            <h1 className="brand-name-c">mmo</h1>
            <p className="card-p-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <h1 className="card-price">₹500</h1>
           <div className="cott-div">
            <h1 className="cot-h1">100% Cotton</h1>
           </div>

        </div>
      </div>
      <div className="pro-card">
        <img className="pro-card-img" src={require("../../assets/img/c5.jpg")} alt="" />
        <div className="card-content">
            <h1 className="brand-name-c">mmo</h1>
            <p className="card-p-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <h1 className="card-price">₹500</h1>
           <div className="cott-div">
            <h1 className="cot-h1">100% Cotton</h1>
           </div>

        </div>
      </div>
      <div className="pro-card">
        <img className="pro-card-img" src={require("../../assets/img/c7.jpg")} alt="" />
        <div className="card-content">
            <h1 className="brand-name-c">mmo</h1>
            <p className="card-p-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <h1 className="card-price">₹500</h1>
           <div className="cott-div">
            <h1 className="cot-h1">100% Cotton</h1>
           </div>

        </div>
      </div>
      <div className="pro-card">
        <img className="pro-card-img" src={require("../../assets/img/c8.jpg")} alt="" />
        <div className="card-content">
            <h1 className="brand-name-c">mmo</h1>
            <p className="card-p-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <h1 className="card-price">₹500</h1>
           <div className="cott-div">
            <h1 className="cot-h1">100% Cotton</h1>
           </div>

        </div>
      </div>
      <div className="pro-card">
        <img className="pro-card-img" src={require("../../assets/img/c9.jpg")} alt="" />
        <div className="card-content">
            <h1 className="brand-name-c">mmo</h1>
            <p className="card-p-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <h1 className="card-price">₹500</h1>
           <div className="cott-div">
            <h1 className="cot-h1">100% Cotton</h1>
           </div>

        </div>
      </div>
      <div className="pro-card">
        <img className="pro-card-img" src={require("../../assets/img/c10.jpg")} alt="" />
        <div className="card-content">
            <h1 className="brand-name-c">mmo</h1>
            <p className="card-p-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <h1 className="card-price">₹500</h1>
           <div className="cott-div">
            <h1 className="cot-h1">100% Cotton</h1>
           </div>

        </div>
      </div>
    </div>
  );
};

export default Productcard;
