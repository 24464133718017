import React, { useEffect, useState } from "react";

import "./Login.css";
// import { auth, db } from "../../components/Firebase/Firebase";
// import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
// import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";

import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  const navigate = useNavigate();

//   const handleSignUp = () => {
//     if (!email || !password || !name || !number) {
//       toast.error("Please Fill All Fields");
//     }else{
//       auth
//       .createUserWithEmailAndPassword(email, password, name, number)
//       .then((userCredentials) => {
//         const user = userCredentials.user;
//         setDoc(doc(db, "user", user.uid), {
//           uid: user.uid,
//           email,
//           name,
//           number,
//           createdAt: serverTimestamp(),
//         }).then(() => {
//           setEmail("");
//           setName("");
//           setPassword("");
//           setNumber("");
//           toast.success("Sign up successfully");
//         });
//         navigate('/')
//       })
//       .catch((error) => alert(error.message));

//     }

    
//   };

  return (
    <>
        <Navbar/>

    
      <div className="sign-div1">
        <div className="sign-div2">
          <div className="head-d-form-lo">
            <h1 className="head-sign">Sign Up</h1>
            <div className="login-form-section">
              {/* <form> */}
              <div className="form-log-d5">
                <div className="div-head-login-from">
                  <div className="login-filed">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Name"
                      className="input-log-in"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <input
                      type="email"
                      name=""
                      id=""
                      placeholder="Email"
                      className="input-log-in"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <input
                      type="password"
                      name=""
                      id=""
                      placeholder="Password"
                      className="input-log-in"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />{" "}
                    <input
                      type="number"
                      name=""
                      id=""
                      placeholder="Contact Number"
                      className="input-log-in"
                      onChange={(e) => setNumber(e.target.value)}
                      value={number}
                    />
                  </div>
                </div>

                <div>
                  <button
                    // onClick={() => {
                    //   handleSignUp();
                    // }}
                    className="btn-sign-in"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
