import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import { getStorage } from "firebase/storage";



const app = firebase.initializeApp({
  apiKey: "AIzaSyAyECfKRomMR4bErMiWXfBGEPMo68qT32c",
  authDomain: "makemyown-c6036.firebaseapp.com",
  projectId: "makemyown-c6036",
  storageBucket: "makemyown-c6036.appspot.com",
  messagingSenderId: "891387277879",
  appId: "1:891387277879:web:ab4c9fbd11d82e0d9b45b2",
  measurementId: "G-0L7D3096H9"
});

export const db = getFirestore(app);
export const storage = getStorage(app);
const auth = firebase.auth()

export default app

export { auth  };
