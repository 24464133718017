import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { HiMenuAlt3, HiOutlineShoppingBag } from "react-icons/hi";
import { BiUser, BiUserCircle } from "react-icons/bi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { auth } from "../Firebase/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import { selectBasketItems } from "../Redux/basketSlice";
import { styled } from "styled-components";

import {GrClose}from 'react-icons/gr'








const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  color: white;
  transition: right 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;




const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navigate = useNavigate();

  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
  };

  const [user] = useAuthState(auth);
  const items = useSelector(selectBasketItems);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={isSticky ? "nav-d2 fadeInDown" : "nav-d1"}>
        <div className="logo-nav">
          <img
            onClick={() => navigate("/")}
            className="logo-nav-img"
            src={require("../../assets/img/1logo.png")}
            alt=""
          />
        </div>

        <div>
          <ul className="ul-nav">
            <NavLink to={"/"}>
              <li className="li-black">Home</li>
            </NavLink>

            <NavLink to={"/Customization"}>
              <li className="li-black">Customization</li>
            </NavLink>
          </ul>
        </div>
        <div className="right-h-content">
          <div className="navb-icons">
            <NavLink to={"/Cart"}>
              <div className="nav-cart-count">
                <HiOutlineShoppingBag size={23} color="gray" />
                <span className="count-span-nav">({items.length})</span>
              </div>
            </NavLink>
            {user ? (
              <>
                <NavLink to={"/Account"}>
                  <BiUserCircle size={23} color="gray" />
                </NavLink>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            <ul className="ul-nav2">
              {user ? (
                <>
                  <button className="log-out-btn-n1" onClick={handleSignOut}>
                    Sign Out
                  </button>
                </>
              ) : (
                <>
                  <Link to={"/Login"}>
                    <li>Login</li>
                  </Link>
                </>
              )}
            </ul>
          </div>

<div  className="menu-op1" >
  <HiMenuAlt3 size={24} color="gray"   onClick={() => setIsOpen(true)} />
</div>

        </div>
      </div>
      <StyledOffCanvas isOpen={isOpen}>
          <div className={isOpen ? "head-mobile-menu" : "none-d-sidenav"}>
            <div className="close-icon-nav">
              <GrClose
                color="black"
                size={20}
                onClick={() => setIsOpen(false)}
              />
            </div>
        
            <div className="">
              <ul className={"side-bar-menu-ul"}>
                {user ? (
                  <>
                    {" "}
                    <div className="right-h-user1">
                      <Link to={"/Account"} className="right-h-user">
                        <BiUser color="black" size={22} />
                        <h1 className={"user-name-nav-black"}>{user?.name}</h1>
                      </Link>
                   
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <li className={""}>
                  <Link to={"/"}>Home</Link>
                </li>

                <li className={""}>
                  <Link to={"/Customization"}>
Customization</Link>
                </li>
              </ul>
              <div className="side-nav-btn-div">
                {user ? (
                  <>
                    <button
                      className="btn-side-nav1"
                      onClick={() => handleSignOut()}
                    >
                      Sign out
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn-side-nav1"
                      onClick={() => navigate("/Login")}
                    >
                      Sign in
                    </button>
                    <button
                      onClick={() => navigate("/Login")}
                      className="btn-side-nav2"
                    >
                      Sign up
                    </button>
                  </>
                )}
                
              </div>
            </div>
          </div>
        </StyledOffCanvas>
        <StyledOffCanvasOverlay
          isOpen={isOpen}
          onClick={() => setIsOpen(false)}
        />

    </>
  );
};

export default Navbar;
