import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "otp-input-react";
import {
  RecaptchaVerifier,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { auth, db } from "../../Components/Firebase/Firebase";
import { CgSpinner } from "react-icons/cg";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";

const Login = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    if (!ph) {
      toast.error("Fill the requested field ");
    } else {
      setLoading(true);
      onCaptchVerify();

      const appVerifier = window.recaptchaVerifier;

      const formatPh = "+" + ph;

      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setShowOTP(true);

          toast.success("OTP sended successfully!");
        })

        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
        const user = res.user;

        setDoc(doc(db, "user", user.uid), {
          uid: user.uid,
          Phone: ph,
          createdAt: serverTimestamp(),
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getDoc(doc(db, "user", user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            setUser(docSnap.data());
          } else {
            console.log("No Document Found");
          }
        });
      } else {
        console.log("no user");

        setUser();
      }
    });
  }, []);

  return (
    <>
      <Navbar />

      <div id="recaptcha-container"></div>
      <div className="log1">
        <div className="log2">
          <div className="log-3">
            <div className="log-right">
              <h1 className="log-h1">Log in / Sign up</h1>
              <p className="log-p1">
                for Latest trends, exciting offers and everything MMO®!
              </p>
              {user ? (
                <>
                  <h2 className="text-center text-white font-medium text-2xl">
                    👍Login Success
                  </h2>
                  <button onClick={handleSignOut}>Log Out</button>
                </>
              ) : (
                <>
                  {showOTP ? (
                    <>
                      <div className="form-log-no">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          autoFocus
                          className="opt-container "
                        ></OtpInput>

                        <div className="log-continue-btn1">
                          <button onClick={onOTPVerify}>Verify OTP</button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-log-no">
                        <div className="react-ph">
                          <PhoneInput
                            country={"in"}
                            value={ph}
                            onChange={setPh}
                          />
                        </div>

                        <div className="log-continue-btn1">
                          <button onClick={onSignup}>Send code via SMS</button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
