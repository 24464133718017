import React, { useState } from 'react'
import './Contactus.css'
import { toast } from 'react-hot-toast';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import { FaMobileAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import Navbar from "../../Components/Navbar/Navbar";
import { db } from '../../Components/Firebase/Firebase';

const Contactus = () => {


    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
  
    const addmessage = () => {
      if (!email || !name || !message) {
        toast.error("Please Fill All Fields");
      } else {
        addDoc(collection(db, "contactUs"), {
          message:message,
          name: name,
          email: email,
          createdAt: serverTimestamp(),
        }).then(() => {
          toast.success(" successfully Send ");
  
          setName("");
          setMessage("");
  
          setEmail("");
        });
      }
    };


  return (
    <div>
      <Navbar />

<div className="new-div1">
        <div className="new-div2 pb-bb  ">
          <h1 className="contactus-h1">Contact US</h1>
          {/* <div className="d-f-contact">
            <div className="d-f-contact2">
              <FaMobileAlt size={50} />
              <h1 className="contact-no-h1">+91 8284936932</h1>
            </div>
            <div className="d-f-contact2">
              <FiMail size={50} />
              <h1 className="contact-no-h1">contact@makemyown.in</h1>
            </div>
          </div> */}
          <div className=" contact-us-div3">
            <div className="contact-us-div4">
              <div className="log-btn-div-check">
                <div className="form-check-address2">
                  <div className="form-check-div2">
                    <div className="df-contact-3">
                      <div className="w-1-c">
                        <input
                          className="input-form-check"
                          id="input"
                          type="text"
                          required
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <label
                          for="input"
                          alt="Name"
                          placeholder="Name"
                        ></label>
                      </div>

                      <div className="w-1-c">
                        <input
                          className="input-form-check"
                          id="input"
                          type="text"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                        <label
                          for="input"
                          alt="Email"
                          placeholder="Email"
                        ></label>
                      </div>
                    </div>

                    <div>
                      <input
                        className="input-form-check2"
                        id="input"
                        type="text"
                        required
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      />
                      <label
                        for="input"
                        alt="Message"
                        placeholder="Message"
                      ></label>
                    </div>
                  </div>
                </div>

                <div className="form-check-address1">
                  <div className="form-check-div2">
                    <div className="chec-out-btn-div">
                      <button
                        onClick={addmessage}
                        className="check-out-btnn-cart1"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Contactus