import React, { useEffect, useState } from "react";

import "./Login.css";
// import { auth, db } from "../../components/Firebase/Firebase";
// import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
// import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Navbar from "../../Components/Navbar/Navbar";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setIsPasswordVisible((prevState) => !prevState);
  // };
  const navigate = useNavigate();
// 
//   const useruid = auth.currentUser;

  const [user, setUser] = useState();

//   useEffect(() => {
//     onAuthStateChanged(auth, (user) => {
//       if (user) {
//         getDoc(doc(db, "user", user.uid)).then((docSnap) => {
//           if (docSnap.exists()) {
//             setUser(docSnap.data());
//           } else {
//             console.log("No Document Found");
//           }
//         });
//       } else {
//         console.log("no user");

//         setUser();
//       }
//     });
//   }, []);

//   const handleLogin = () => {
//     if (!email || !password) {
//       toast.error("Please Fill All Fields");
//     } else {
//       signInWithEmailAndPassword(auth, email, password)
//         .then(() => {
//           navigate("/");
//           toast.success("Sign in successfully");
//         })
//         .catch((error) => alert(error.message));

     
//     }
//   };

  return (
    <>
        <Navbar/>

      <div className="sign-div1">
        <div className="sign-div2">
          <div className="head-d-form-lo">
            <h1 className="head-sign">Sign In</h1>
            <div className="login-form-section">
              {/* <form onSubmit={handleLogin}> */}
              <div className="form-log-d5">
                <div className="div-head-login-from">
                  <div className="login-filed">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Email Address"
                      className="input-log-in"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <input
                      type="password"
                      name=""
                      id=""
                      placeholder="Password"
                      className="input-log-in"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                </div>
                {/* <p className="forg-p">Forgotten your password?</p> */}
                <div>
                  <button
                    onClick={() => {
                    //   handleLogin();
                    }}
                    className="btn-sign-in"
                  >
                    Sign In
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
