import React, { useEffect, useState } from "react";
import "./ProductView.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";


import Navbar from "../../Components/Navbar/Navbar";
import { AiOutlineHeart } from "react-icons/ai";
import { BiSolidShoppingBag } from "react-icons/bi";
import { IoStatsChartOutline } from "react-icons/io5";
import { GrClose } from "react-icons/gr";



import styled from "styled-components";

const StyledOffCanvas = styled.aside`
  position: fixed;
  width: 300px;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : "-100%")};
  height: 100%;
  background-color: white;
  z-index: 1000;
  color: white;
  transition: right 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const ProductView = () => {

  const [isOpen, setIsOpen] = useState(false);


  const [isLoading, setIsLoading] = useState(true);
useEffect(() => {

 setTimeout(() => {
        setIsLoading(false);
      }, 4000);



}, [])


  if (isLoading) {
    return (
      <>
        <Navbar />

        <div>
          <div className="product-div1">
            <div className="product-div2">
              <div className="main-div-carousel">
                <div className="pro-sket-imc"></div>
                <div className="right-h-details-product">
                  <div className="r-h-ske-p">

                  </div>
                </div>
              </div>

              <div className="details-centent-a1">
                <div className="details-centent-a2">
                  <div className="product-description">
                    <div className="s-c-div2">
                      <div className="head-c-decs">
                        <span className="line-texts"></span>
                        <h1 className="pro-description-h1">Returns & Refund</h1>
                        <span className="line-texts"></span>
                      </div>
                      <p className="details-para1">
                        Easy 15 days return and exchange. Return Policies may
                        vary based on products and promotions. For full details
                        on our Returns Policies, please
                      </p>
                    </div>
                  </div>
              

      
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }



  return (
    <>
      <Navbar />
      <div>
      <div className="product-div1">
        <div className="product-div2">
          <div className="main-div-carousel">
            <div>

       
        <Carousel
   
   showStatus={false}
   showIndicators={false}
   showArrows={false}
   selectedItem={0}
   infiniteLoop
   autoPlay
   stopOnHover
   interval={3000}
   transitionTime={500}
   dynamicHeight={false}
   >
                <div>
                    <img src={require('../../assets/img/c2.jpeg')} />
                   
                </div>
                <div>
                <img src={require('../../assets/img/c3.jpg')} />

                 
                </div>
                <div>
                <img src={require('../../assets/img/c4.jpg')} />

                </div>
            </Carousel>
     </div>
     <div className="right-h-details-product">
              <div>
                <h1 className="heading-product-r1">Brand</h1>
                <h1 className="price-product-r1">Mrp ₹599</h1>
                <p className="in-tax">Price inclusive of all taxes</p>

                <p className="in-tax av-not-av">Available</p>


                {/* {available === true ? (
                  <>
                    <p className="in-tax av-not-av">Available</p>
                  </>
                ) : (
                  <>
                    <p className="in-tax av-not">Out Of Stock</p>
                  </>
                )} */}
              </div>
              {/* {pcolor.length === 0 ? (
                <></>
              ) : (
                <>
                  <div className="s-c-div1">
                    <h1 className="av-color">Colors Available</h1>
                    {selectcolor ? (
                      <h1 className="av-color">Selected Color:{selectcolor}</h1>
                    ) : (
                      <></>
                    )}
                    <div className="select-c-div">
                      {Array.isArray(pcolor)
                        ? pcolor.map((item, key) => {
                            return (
                              <>
                                <div
                                  style={{ backgroundColor: item.Color }}
                                  className="color-select"
                                  onClick={() => selectedcolor(item.Color)}
                                ></div>
                              </>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </>
              )} */}

              <div className="s-c-div1">
                <h1 className="av-size">Select Size</h1>
                {/* {selectsize ? (
                  <>
                    <h1 className="av-color">Selected Size:{selectsize}</h1>
                  </>
                ) : (
                  <></>
                )} */}
                {/* <div className="ss-pro1">
                  {Array.isArray(psize)
                    ? psize.map((item, key) => {
                        return (
                          <>
                          {item?.Available === true ? <>
                            <div
                              onClick={() => selectedSize(item.Size)}

                        
                            

                              className={ selectsize === item.Size ? 's-c-size-div2' :"s-c-size-div"} 
                            >
                        
                              <span> {item.Size}</span>
                            </div>
                          
                          </>:<>
                          
                          
                          </>}
                            
                          </>
                        );
                      })
                    : null}
                </div> */}

                <div className="chart-div">
                  <IoStatsChartOutline color=" #176d93" />
                  <h1
                  
                  onClick={() => setIsOpen(true)} 
                  
                  className="text-check-s">
                    Check Size Chart
                  </h1>
                </div>
              </div>
              <p style={{fontSize:'11px'}}>HANDPICKED STYLES | ASSURED QUALITY</p>  
              <button
                        //   onClick={addItemToBasket}
                          className="btn-add-to-bag"
                        >
                          <BiSolidShoppingBag size={20} />
                          Add to bag
                        </button>
           
              {/* {available === true ? (
                <div className="btn-s-con">
                  {selectsize ? (
                    <>
                      {pcolor.length === 0 ? (
                        <button
                        //   onClick={addItemToBasket}
                          className="btn-add-to-bag"
                        >
                          <BiSolidShoppingBag size={20} />
                          Add to bag
                        </button>
                      ) : (
                        <>
                          {selectcolor ? (
                            <button
                            //   onClick={addItemToBasket}
                              className="btn-add-to-bag"
                            >
                              <BiSolidShoppingBag size={20} />
                              Add to bag
                            </button>
                          ) : (
                            <button
                            //   onClick={selectcolortost}
                              className="btn-add-to-bag"
                            >
                              <BiSolidShoppingBag size={20} />
                              Select Color
                            </button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <button 
                    
                    
                    // onClick={selectsizetost} 
                    
                    
                    className="btn-add-to-bag">
                      <BiSolidShoppingBag size={20} />
                      Select Size
                    </button>
                  )}

                  <p>HANDPICKED STYLES | ASSURED QUALITY</p>
                  <button
                    // onClick={() => handleAddBookSubmit()}
                    className="btn-add-to-wish"
                  >
                    <AiOutlineHeart size={20} />
                    Save To wishlist
                  </button>
                </div>
              ) : (
                <div className="btn-s-con">
                  <button className="btn-add-to-bag">
                    <BiSolidShoppingBag size={20} />
                    Out of stock
                  </button>
                  <p>HANDPICKED STYLES | ASSURED QUALITY</p>
                </div>
              )} */}
            </div>

        </div>

        <div className="details-centent-a1">
            <div className="details-centent-a2">

            <div className="product-description">
                  <div className="s-c-div2">
                    <div className="head-c-decs">
                      <span className="line-texts"></span>
                      <h1 className="pro-description-h1">
                        Product Description
                      </h1>
                      <san className="line-texts"></san>
                    </div>
                    <p className="details-para1">
           Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium voluptatum earum quam unde voluptatem repellat repudiandae at adipisci! Aliquid ut molestiae ipsum adipisci velit quidem explicabo laborum culpa odit sint?
                    </p>
                  </div>
                </div>

              {/* {description ? (
                <div className="product-description">
                  <div className="s-c-div2">
                    <div className="head-c-decs">
                      <span className="line-texts"></span>
                      <h1 className="pro-description-h1">
                        Product Description
                      </h1>
                      <san className="line-texts"></san>
                    </div>
                    <p className="details-para1">
                      {description}
                      {descriptionss}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )} */}

              <div className="product-description">
                <div className="s-c-div2">
                  <div className="head-c-decs">
                    <span className="line-texts"></span>
                    <h1 className="pro-description-h1">Returns & Refund</h1>
                    <span className="line-texts"></span>
                  </div>
                  <p className="details-para1">
                    Easy 15 days return and exchange. Return Policies may vary
                    based on products and promotions. For full details on our
                    Returns Policies, please
                  </p>
                </div>
              </div>
             
     
            </div>
          </div>

        </div>
        </div>

      </div>

      <StyledOffCanvas isOpen={isOpen}>
        <>
          <div className="side-cart-show">
            <div className="close-icon-nav">
              <GrClose
                color="black"
                size={20}
                onClick={() => setIsOpen(false)}
              />
            </div>

            <table className="table">
              <thead>

              <tr>
                            <th>item</th>
                            <th>item</th>
                          
                              <th>item</th>
                          
                          </tr>

                {/* {Array.isArray(size)
                  ? size.map((item, key) => {
                      return (
                        <>
                          <tr>
                            <th>{item.Size}</th>
                            <th>{item.Chest}</th>
                            {item.BodyLength ? (
                              <th>{item.BodyLength}</th>
                            ) : (
                              <></>
                            )}
                          </tr>
                        </>
                      );
                    })
                  : null} */}
              </thead>
            </table>
          </div>
        </>
      </StyledOffCanvas>
      <StyledOffCanvasOverlay
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
      />
    </>
 
  );
};

export default ProductView;
