import React, { useEffect, useMemo, useState } from "react";
import "./Cart.css";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../Components/Firebase/Firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromBasket,
  removeAllItems,
  selectBasketItems,
  selectBasketTotal,
} from "../../Components/Redux/basketSlice";

import Currency from "react-currency-formatter";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Cart = () => {
  const navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const [user] = useAuthState(auth);

  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 4000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const items = useSelector(selectBasketItems);
  const [groupedItemsInBasket, setGroupedItemsInBasket] = useState([]);
  const dispatch = useDispatch();
  useMemo(() => {
    const groupedItems = items.reduce((results, item) => {
      (results[item.id] = results[item.id] || []).push(item);
      return results;
    }, {});

    setGroupedItemsInBasket(groupedItems);
    setIsLoading(false);
  }, [items]);
  const basketTotal = useSelector(selectBasketTotal);

  const [inputcoupon, setinputcoupon] = useState("");
  const [checkcoupon, setcheckcoupon] = useState();
  const [totalAmount, setTotalAmount] = useState(basketTotal);

  const onClickCoupon = () => {
    getDoc(doc(db, "coupon", inputcoupon)).then((docSnap) => {
      if (docSnap.exists()) {
        setTotalAmount(
          totalAmount -
            (parseInt(docSnap.data()?.discount) / 100) * parseInt(totalAmount)
        );
        console.log("Document data:", docSnap.data());
        setcheckcoupon(docSnap.data());
        toast.success("Coupon  added successfully");
      } else {
        setTotalAmount(totalAmount);
        toast.success("No such document!");

        console.log("No such document!");
      }
      setIsOpen(false);


    });
  };



  

  if (isLoading) {
    return (
      <>
        <div className="header-cart-show">
          <img
            onClick={() => navigate("/")}
            src={require("../../assets/img/1logo.png")}
            style={{ width: "70px", cursor: "pointer" }}
            alt=""
          />

          <div className="top-cart-h2">
            {user ? (
              <>
                <span className="span-1-cart">Signed as</span>
                <span className="span-2-cart">{user?.phoneNumber}</span>
              </>
            ) : (
              <>
                <ul className="ul-nav2">
                  <Link to={"/Login"}>
                    <li>Login</li>
                  </Link>
                </ul>
              </>
            )}
          </div>
        </div>
        <hr />

        <div className="card-d1">
          <div className="card-d2">
            <div className="line-empty-cart">
              <div className="line-empty-cart2"></div>
            </div>
            <div className="cart-content">
              <div className="left-h-cart">
                <div className="inner-content2"></div>
              </div>
              <div className="inner-content33"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="header-cart-show">
        <img
          onClick={() => navigate("/")}
          src={require("../../assets/img/1logo.png")}
          style={{ width: "70px", cursor: "pointer" }}
          alt=""
        />

        <div className="top-cart-h2">
          {user ? (
            <>
              <span className="span-1-cart">Signed as</span>
              <span className="span-2-cart">{user?.phoneNumber}</span>
            </>
          ) : (
            <>
              <ul className="ul-nav2">
                <Link to={"/Login"}>
                  <li>Login</li>
                </Link>
              </ul>
            </>
          )}
        </div>
      </div>
      <hr />

      {items.length === 0 ? (
        <>
          <div className="empty-cart">
            <div className="empty-cart2">
              <img
                className="empty-cart-img"
                src={require("../../assets/img/sssss.png")}
                alt=""
              />
              <p className="e-para">Nothing in the bag</p>
              <button onClick={() => navigate("/")} className="e-btn-c">
                Continue Shopping
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card-d1">
            <div className="card-d2">
              <h1 className="count-bag">
                My Bag
                <span className="inner-count-cart"> {items.length} item</span>
              </h1>
              <div className="cart-content">
                <div className="cart-data-d">
                  {Object.entries(groupedItemsInBasket).map(([key, items]) => (
                    <div key={key}>
                      <div className="left-h-cart">
                        <div className="inner-content">
                          <div className="delete-item-pro">
                            <MdDeleteOutline
                              onClick={() =>
                                dispatch(removeFromBasket({ id: key }))
                              }
                              size={20}
                            />
                          </div>
                          <div className="l-h-c">
                            <p className="lh-p1cart">
                              Men's White Customizable T-Shirt
                            </p>
                            <h1 className="cart-p-price">
                              ₹ {items[0]?.price}
                            </h1>
                            <div className="s-qty-div">
                              <div className="s-cc">
                                <h1>
                                  Size:{" "}
                                  <span className="span-h-carts">
                                    {" "}
                                    {items[0]?.selectsize}
                                  </span>
                                </h1>
                              </div>
                              <div
                                className="s-cc"
                                style={{ backgroundColor: items[0]?.tcolor }}
                              ></div>
                              <div className="qty-cc">
                                <h1>
                                  Qty:{" "}
                                  <span className="span-h-carts">
                                    {items.length}
                                  </span>
                                </h1>
                              </div>
                            </div>
                            <p className="lh-p1cart2">
                              Not eligible for returns or cancellation
                            </p>
                          </div>

                          <div className="r-h-c">
                            <img src={items[0]?.imagecap} alt="" />

                       

                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="right-h-cart">
                  <div className="right-cart-content">
                    <div className="main-r-c">

{checkcoupon ? <>


  <div className="coupon-add" >
                        <p style={{textTransform:"uppercase"}}>Coupon  added successfully</p>
                        <p>Redeem </p>
                      </div>

</>:<>

<div className="coupon-add" onClick={openModal}>
                        <p>Apply Coupon / Gift Card / Referral</p>
                        <p>Redeem </p>
                      </div>
</>}


                   
                      <div className="price-summary ">
                        <p>PRICE SUMMARY</p>
                      </div>
                      <div className="p1-c-c1">
                        <div className="prce-details-total">
                          <p>Estimated Delivery & Handling</p>
                          <p>₹100</p>
                        </div>
                        {checkcoupon && (

                          <div className="prce-details-total">
                          <p>Coupon discount</p>
                          <p>{checkcoupon?.discount}%</p>
                        </div>
                          )}

                        <div className="prce-details-total">
                          <p>Subtotal</p>
                          <p>
                            {" "}
                            <Currency quantity={basketTotal} currency="INR" />
                          </p>
                        </div>
                      </div>
                      <div className="total-se-cart">
                        <span>Total</span>
                        <p>
                          <Currency quantity={totalAmount} currency="INR" />
                        </p>
                      </div>
                      {user ? (
                        <>
                          <div className="r-h-c-r1">
                            <button onClick={() => navigate("/CheckOut",{state:{ total:totalAmount }})}>
                              Checkout
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="r-h-c-r1">
                            <button onClick={() => navigate("/CheckOut",{state:{ total:totalAmount }})}>
                              Guest Checkout
                            </button>
                            <button onClick={() => navigate("/Login")}>
                              Member Checkout
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <>
            <div className="modal-cart-coupon">
              <div className="close-m2">
                <div></div>
                <p className="para-c-m">Apply Coupon / Gift Card</p>
                <AiOutlineClose onClick={closeModal} color="black" size={20} />
              </div>
              <div className="coupon-c-modal">
                <div class="form__group field f-f-form-mm">
                  <input
                    type="input"
                    class="form__field"
                    placeholder="Name"
           
                    onChange={(e) => setinputcoupon(e.target.value)}
                    value={inputcoupon} 
                    required
                  />
                  <label for="name" class="form__label">
                    Enter Code
                  </label>
                  <div className="apply-btn-Coupon">
                    <button onClick={onClickCoupon}>Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal>
      </div>
    </>
  );
};

export default Cart;
