import React, { useEffect, useState, useRef } from "react";
import {
  Image,
  Transformer,
  Circle,
  Text,
  Tag,
  Label,
  Group,
} from "react-konva";
import { MdDeleteOutline, MdOutlineDeleteOutline } from "react-icons/md";
import useImage from "use-image";
import { FaWindowClose } from "react-icons/fa";

export default function ImageTransformer({
  index,
  handleDelete,
  url,
  didTouchStage,
  onDelete,
  ...rest
}) {
  const [image] = useImage(url);
  const transformerRef = useRef();
  const imageRef = useRef();
  const deleteCircleRef = useRef();
  const [isSelected, setIsSelected] = useState(false);

  function selectHandler(data) {
    setIsSelected(data);
  }

  useEffect(() => {
    if (isSelected) {
      // transformerRef.current.nodes([imageRef.current, deleteCircleRef.current]);
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    if (didTouchStage) {
      selectHandler(false);
    }
  }, [didTouchStage]);

  const handleDeleteimag = () => {
    handleDelete(index);
  };

  return (
    <>
      <Image
        ref={imageRef}
        {...rest}
        image={image}
        onTap={() => {
          selectHandler(true);
        }}
        onClick={() => {
          selectHandler(true);
        }}
        draggable
      />
      {isSelected && (
        <>
          <Transformer
            ref={transformerRef}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          >
{/*  
  <Circle
              ref={deleteCircleRef}
              radius={15}
              fill="red"
              width={10}

              x={0}
              y={0}
              onClick={() => handleDeleteimag()}
              ></Circle> */}


            <Text
              ref={deleteCircleRef}
              x={-12}
              y={-15}
              text="×" // You can use any Unicode character or an SVG icon here
              fontSize={40}
              fill="black"
              onTap={() => {
                handleDeleteimag()
              }}
              onClick={() => handleDeleteimag()} // Define the action when the icon is clicked
         
            />
          </Transformer>
        </>
      )}
    </>
  );
}






