import React from "react";
import "./Customization.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
const Customization = () => {
  const navigate= useNavigate();
  return (
    <>
        <Navbar/>
    
   
    <div className="cust-d1">
      <div className="card-d-cus">
        <div className="card-cus-1" onClick={()=> navigate('/Product-Customization')} >
        <img
            className="card-cus-img"
            src={require("../../assets/img/oversize.png")}
            alt=""
          />
          <h1 className="h-overs">OverSize</h1>
        </div>
        <div className="card-cus-1"  onClick={()=> navigate('/Product-Customization')} >
          <img
            className="card-cus-img"
            src={require("../../assets/img/regular.png")}

            alt=""
          />
          <h1 className="h-overs">Regular Fit</h1>

        </div>
      </div>
    </div>
 </>
  );
};

export default Customization;
