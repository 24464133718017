import React, { useEffect, useState } from "react";


import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import './Account.css'
import Navbar from "../../Components/Navbar/Navbar";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../Components/Firebase/Firebase";
const Account = () => {

  const [userID] = useAuthState(auth);

  const navigate = useNavigate();
  const [user, setUser] = useState();

  useEffect(() => {
    try {
      getDoc(doc(db, "user", userID?.uid)).then((docSnap) => {
        if (docSnap.exists()) {
          setUser(docSnap.data());
        } else {
          console.log("No Document Found");
        }
      });
    } catch {}
  }, [user, userID?.uid]);
  // const uid = auth.currentUser.uid;

  const [addresss, setAddresss] = useState([]);

  useEffect(() => {
    try {
      const q = collection(db, "user", userID?.uid, "Address");

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setAddresss(cities);
      });
    } catch {}
  }, [userID?.uid]);

  useEffect(() => {
    const sendup = async (item) => {
      const washingtonRef = doc(db, "user", userID?.uid);
      await updateDoc(washingtonRef, {
        defaultAddressid: item,
      });
    };
  }, []);


  const [name, setName] = useState();

  const [phone, setPhone] = useState();
  const [pincode, setPincode] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [flat, setFlat] = useState();
  const [landmark, setLandmark] = useState();

  const sendform = (e) => {
    if (
      !email ||
      !name ||
      !pincode ||
      !address ||
      !city ||
      !state ||
      !flat ||
      !landmark ||
      !phone
    ) {
      toast.error("Please Fill All Fields");
    } else {
      e.preventDefault();

      addDoc(collection(db, "user", userID?.uid, "Address"), {
        name,
        phone,
        pincode,
        address,
        city,
        state,
        flat,
        landmark,
        email,
        defaultAddress: false,
        createdAt: serverTimestamp(),
      }).then((docRef) => {

        const washingtonRef = doc(db, "user", userID?.uid);
        updateDoc(washingtonRef, {
          defaultAddressid: docRef?.id,
        });
        



        setName("");
        setPhone("");
        setPincode("");
        setAddress("");
        setState("");
        setFlat("");
        setLandmark("");
        setCity("");
        setEmail("");
      });
      toast.success("Address added successfully");
    }
  };

  const updateform = async (item) => {
    if (
      !email ||
      !name ||
      !pincode ||
      !address ||
      !city ||
      !state ||
      !flat ||
      !landmark ||
      !phone
    ) {
      toast.error("Please Fill All Fields");
    } else {
      const washingtonRef = doc(db, "user", userID?.uid, "Address", select?.id);
      await updateDoc(washingtonRef, {
        name,
        phone,
        pincode,
        address,
        city,
        state,
        flat,
        landmark,
        email,
        defaultAddress: false,
        createdAt: serverTimestamp(),
      }).then(() => {
        setName("");
        setPhone("");
        setPincode("");
        setAddress("");
        setState("");
        setFlat("");
        setLandmark("");
        setCity("");
        setEmail("");
      });
      toast.success("Address updated successfully");
    }
  };

  const sendup = async (item) => {
    const washingtonRef = doc(db, "user", userID?.uid);
    await updateDoc(washingtonRef, {
      defaultAddressid: item,
    });
  };

  async function deleteaddress(id) {
    await deleteDoc(doc(db, "user", userID?.uid, "Address", id));
  }

  const tostalert = () => {
    toast.error("If you wanna add a new address Delete previous one ! ");
  };

  const [select, setSelect] = useState();
  const choose = (option) => {
    toast.success("Please Fill All Fields");

    setSelect(option);
  };

  return (
    <>
        <Navbar/>

      <div className="div-acc-1">
        <div className="div-acc-2">
          <div className="lh-a">
            <div className="">
              <h1 className="head-ach1">Personal Information</h1>
              <p className="p-acc-det">
                Hey there! Fill in your details for a personalized Mmo
                shopping experience.
              </p>
            </div>
            <>
              {select ? (
                <>
                  <div className="log-btn-div-check">
                    <h1 className="check-h1">Update your name and address:</h1>

                    <div className="form-check-address">
                      <div className="form-check-div2">
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                          <label
                            for="input"
                            alt="Name"
                            placeholder="Name"
                          ></label>
                        </div>

                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          <label
                            for="input"
                            alt="Email"
                            placeholder="Email"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setFlat(e.target.value)}
                            value={flat}
                          />
                          <label
                            for="input"
                            alt="Flat/House No"
                            placeholder="Address Line 1"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                          />
                          <label
                            for="input"
                            alt="Address Line 1"
                            placeholder="Address Line 1"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setLandmark(e.target.value)}
                            value={landmark}
                          />
                          <label
                            for="input"
                            alt="Landmark"
                            placeholder="Landmark"
                          ></label>
                        </div>
                        <div className="d-f-c-8">
                          <div>
                            <input
                              className="input-form-check"
                              id="input"
                              type="text"
                              required
                              onChange={(e) => setPincode(e.target.value)}
                              value={pincode}
                            />
                            <label
                              for="input"
                              alt="Pin Code"
                              placeholder="Pin Code"
                            ></label>
                          </div>
                          <div>
                            <input
                              className="input-form-check"
                              id="input"
                              type="text"
                              required
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                            />
                            <label
                              for="input"
                              alt="City"
                              placeholder="City"
                            ></label>
                          </div>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                          />
                          <label
                            for="input"
                            alt="State"
                            placeholder="State"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <h1 className="check-h1">
                      What's your contact information?:
                    </h1>
                    <div className="form-check-address">
                      <div className="form-check-div2">
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                          />
                          <label
                            for="input"
                            alt="Phone Number"
                            placeholder="Phone Number"
                          ></label>
                        </div>
                        <div className="chec-out-btn-div">
                          <button
                            // onClick={updateform}
                            className="check-out-btnn-cart1"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="log-btn-div-check">
                    <h1 className="check-h1">Add your name and address:</h1>

                    <div className="form-check-address">
                      <div className="form-check-div2">
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                          <label
                            for="input"
                            alt="Name"
                            placeholder="Name"
                          ></label>
                        </div>

                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          <label
                            for="input"
                            alt="Email"
                            placeholder="Email"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setFlat(e.target.value)}
                            value={flat}
                          />
                          <label
                            for="input"
                            alt="Flat/House No"
                            placeholder="Address Line 1"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                          />
                          <label
                            for="input"
                            alt="Address Line 1"
                            placeholder="Address Line 1"
                          ></label>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setLandmark(e.target.value)}
                            value={landmark}
                          />
                          <label
                            for="input"
                            alt="Landmark"
                            placeholder="Landmark"
                          ></label>
                        </div>
                        <div className="d-f-c-8">
                          <div>
                            <input
                              className="input-form-check"
                              id="input"
                              type="text"
                              required
                              onChange={(e) => setPincode(e.target.value)}
                              value={pincode}
                            />
                            <label
                              for="input"
                              alt="Pin Code"
                              placeholder="Pin Code"
                            ></label>
                          </div>
                          <div>
                            <input
                              className="input-form-check"
                              id="input"
                              type="text"
                              required
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                            />
                            <label
                              for="input"
                              alt="City"
                              placeholder="City"
                            ></label>
                          </div>
                        </div>
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                          />
                          <label
                            for="input"
                            alt="State"
                            placeholder="State"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <h1 className="check-h1">
                      What's your contact information?:
                    </h1>
                    <div className="form-check-address">
                      <div className="form-check-div2">
                        <div>
                          <input
                            className="input-form-check"
                            id="input"
                            type="text"
                            required
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                          />
                          <label
                            for="input"
                            alt="Phone Number"
                            placeholder="Phone Number"
                          ></label>
                        </div>
                        {addresss.length === 2 ? (
                          <>
                            <div className="chec-out-btn-div">
                              <button
                                onClick={tostalert}
                                className="check-out-btnn-cart1"
                              >
                                Add
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="chec-out-btn-div">
                              <button
                                onClick={sendform}
                                className="check-out-btnn-cart1"
                              >
                                Add
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
          <div className="rh-a">
            <div className="">
              <h1 className="head-ach1">Address Book</h1>
              <p className="p-acc-det">
                Save all your addresses for a faster checkout experience. and
                Mark as Default Address then ur address show in checkout
                section!
              </p>
              <h1 className="name-add">User Phone no : {user?.Phone}</h1>

              {Array.isArray(addresss)
                ? addresss.map((item, key) => {
                    return (
                      <>
                        <div className="show-add">
                          <div className="def-add">
                            <h1 className="name-add">{item.name}</h1>

                            {user?.defaultAddressid === item.id ? (
                              <>
                                <h1 className="defaultaddress">
                                  Default Address
                                </h1>
                              </>
                            ) : (
                              <>
                                <button onClick={() => sendup(item.id)}>
                                  Mark as default
                                </button>
                              </>
                            )}
                          </div>

                          <p className="address-p">{item.email}</p>
                          <p className="address-p">{item.address}</p>
                          <p className="address-p">
                            {item.flat},{item.address},{item.landmark},{" "}
                            {item.pincode},{item.state}, {item.city}
                          </p>
                          <h1 className="phone-no-ad">
                            Phone: <span>{item.phone}</span>
                          </h1>
                          <button onClick={() => deleteaddress(item.id)}>
                            Delete
                          </button>
                          <button className="" onClick={() => choose(item)}>
                            Edit
                          </button>
                        </div>
                      </>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
