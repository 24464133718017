import React, { useState } from 'react';
import { Layer, Stage } from 'react-konva';
import ImageTransformer from './Product/ImageTransformer';

function ImageUploader() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageWidth, setImageWidth] = useState(100); 
  const [imageHeight, setImageHeight] = useState(100);
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedImage(URL.createObjectURL(selectedFile));
      setImageWidth(200);
    }
  };

  const handleAutoAdjust = () => {
    if (selectedImage) {
      const image = new Image();
      image.src = selectedImage;
  
      image.onload = () => {
        const maxWidth = 500; // Maximum width for the image
        const maxHeight = 500; // Maximum height for the image
        const originalWidth = image.width;
        const originalHeight = image.height;
  
        const aspectRatio = originalWidth / originalHeight;
  
        let newWidth = originalWidth;
        let newHeight = originalHeight;
  
        // Adjust width if needed
        if (originalWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;
        }
  
        // Adjust height if needed
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }
  
        setImageWidth(newWidth);
        setImageHeight(newHeight);
      };
    }
  };
  
  const [selectedId, selectShape] = React.useState(null);

  const [didTouchStage, setDidTouchStage] = useState(false);

  const checkDeselect = (e) => {
    const didTouchStage = e.target === e.target.getStage();
    setDidTouchStage(didTouchStage);
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };
  const handleHeightChange = (event) => {
    setImageHeight(event.target.value);
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageChange} />





      <Stage
                                  width={"300"}
                                  height={"500"}
                                  className="stage-img-c"
                                  // width={window.innerWidth}
                                  // height={window.innerHeight}
                                  onMouseDown={checkDeselect}
                                  onTouchStart={checkDeselect}
                                >
                                  <Layer>




                                  {selectedImage && (
        <div>
       <ImageTransformer
                                        //   index={index}
                                        //   handleDelete={handleDeleteImage}
                                          url={selectedImage}
                                        //   didTouchStage={didTouchStage}
                                        width={imageWidth} 
                                        height={imageHeight}
                                         
                                          x={10}
                                          y={30}
                                        />
        </div>
      )}



                                 


                        
                           
                                  </Layer>
                                </Stage>

                                <button onClick={handleAutoAdjust }>Auto Adjust Width</button>



    </div>
  );
}

export default ImageUploader;
