import { MdDeleteOutline } from "react-icons/md";
import { Circle, Text, Transformer } from "react-konva";
import React, { useEffect, useRef, useState } from "react";

const TheText = ({
    textProps,
    isSelected,
    onSelect,
    onChange,
    onDelete,
    color,
    fontFamily,
    ...rest
  }) => {
    const textRef = useRef();
    const trRef = useRef();
    const deleteCircleRef = useRef();
  
    React.useEffect(() => {
      if (isSelected) {
        
        trRef.current.nodes([textRef.current]);
        trRef.current.getLayer().batchDraw();


        // trRef.current.nodes([textRef.current,deleteCircleRef.current]);
        // trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
    const handleDeletetext = () => {
    onDelete(textProps.id);
  };
    return (
      <React.Fragment>
        <Text
        {...rest}

          onClick={onSelect}
          fill={color}
          fontFamily={fontFamily}
          onTap={onSelect}
          ref={textRef}
          {...textProps}
          onDragEnd={(e) => {
            onChange({
              ...textProps,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
          onTransformEnd={(e) => {
            const node = textRef.current;
            // const scaleX = node.scaleX();
            // const scaleY = node.scaleY();
  
            // node.scaleX(1);
            // node.scaleY(1);
            onChange({
              ...textProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: node.width(),
              height: node.height(),
            });
          }}
        />
  
  



{isSelected && (
        <>
          <Transformer
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          >
         

<Text
              ref={deleteCircleRef}
              x={-12}
              y={-15}
              text="×" // You can use any Unicode character or an SVG icon here
              fontSize={40}
              fill="black"
              onTap={() => {
                handleDeletetext()
              }}
              onClick={() => handleDeletetext()} // Define the action when the icon is clicked
            
            />


          </Transformer>
        </>
      )}



      </React.Fragment>
    );
  };
  export default TheText;