import React from "react";
import "./Home.css";
import Productcard from "../../Components/Productcards/Productcard";
import Navbar from "../../Components/Navbar/Navbar";
const Home = () => {
  return (
    <>
      <Navbar />
      <div>
        <div className="banner-img-home">
          <img
            className="banner-img"
            src={require("../../assets/img/banner.jpg")}
            alt=""
          />
        </div>

        <div className="home-d3">
          <div className="home-d4">
            <div className="pp-desigin">
              <p>don't miss</p>
              <h1>Popular Designs </h1>
            </div>
            <Productcard />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
