import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./Style.scss";

import { Toaster } from "react-hot-toast";
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import Product from "./Pages/Product/Product";
import Test from "./Pages/Test";
import Customization from "./Pages/Customization/Customization";
import Footer from "./Components/Footer/Footer";

import ScrollToTop from "./Components/Scrolltop/ScrollToTop";
import ScrollTop from "./Components/Scrolltop/ScrollTop";
import Signin from "./Pages/Login/Signin";
import Signup from "./Pages/Login/Signup";
import Account from "./Pages/Account/Account";
import Cart from "./Pages/Cart/Cart";
import CheckOut from "./Pages/Checkout/Checkout";
import Login from "./Pages/Login/Login";
import { Provider } from "react-redux";
import store from "./Store";
import ProductView from "./Pages/ProductView/ProductView";
import Contactus from "./Pages/Contactus/Contactus";
import Privacy from "./Pages/Policy/Privacy";
import Term from "./Pages/Policy/Term";
import Refund from "./Pages/Policy/Refund";

function App() {
  return (
    <>
        <Provider store={store}>

   
      <BrowserRouter>
        <Toaster />

        <Toaster />
        <ScrollToTop />
        <ScrollTop />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Product-Customization" element={<Product />} />
          <Route path="/sign-in" element={<Signin />} />

          <Route path="/Sign-up" element={<Signup />} />
          <Route path="/Customization" element={<Customization />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/CheckOut" element={<CheckOut />} />
          <Route path="/Test" element={<Test />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/ProductView" element={<ProductView/>} />
          <Route path="/Contactus" element={<Contactus/>} />
          <Route exact path="/Privacy-Policy" element={<Privacy />} />
            <Route exact path="/Terms-Conditions" element={<Term/>} />

            <Route exact path="/Refund-Shipping-Policy" element={<Refund/>} />


        </Routes>

        <Footer />
      </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
