import React, { useEffect, useRef, useState } from "react";
import "./Product.css";
import { AiFillCheckCircle, AiOutlineClose } from "react-icons/ai";
import { MdFlipCameraAndroid } from "react-icons/md";

import Modal from "react-modal";
import { TbTextSize } from "react-icons/tb";
import { BsCheck2, BsFillCheckCircleFill, BsImage } from "react-icons/bs";
import { BiText } from "react-icons/bi";
import { RiFontSize } from "react-icons/ri";
import { IoIosColorPalette } from "react-icons/io";

import Navbar from "../../Components/Navbar/Navbar";
import { Stage, Layer } from "react-konva";

import html2canvas from "html2canvas";
import ImageTransformer from "./ImageTransformer";
import TheText from "./TextTransformer";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addToBasket } from "../../Components/Redux/basketSlice";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../Components/Firebase/Firebase";
import { useNavigate } from "react-router-dom";
import { FaWindowClose } from "react-icons/fa";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const initialTexts = [];

const Product = () => {
  const navigate = useNavigate();

  const [selectsize, setSeclectsize] = useState();

  const sizeselected = (option) => {
    setSeclectsize(option);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const [modalIsOpen2, setIsOpen2] = useState(false);

  const openmodal2 = () => {
    setIsOpen2(true);
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  const [tsection, setTsection] = useState(false);

  const [activeTab, setActiveTab] = useState("tab1"); // Default active tab

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const fontOptions = [
    "Roboto",
    "Oswald",
    "Bad Script",
    "Chewy",
    "Satisfy",
    "Indie Flower",
    "Poppins",
    "Montserrat",
    "Open Sans",
    " Bebas Neue",
    "Comic Neue",
    "GFS Didot",
    "Lato",
    "Lora",
    "Lilita One",
    "Russo One",
    "Righteous",
    "Great Vibes",
  ];

  const fontscolor = [
    "black",
    "rgb(231, 76, 60)",
    "rgb(39, 174, 96)",
    "white",
    "rgb(255, 87, 34)",
    " rgb(255, 235, 59)",
    " rgb(13, 71, 161)",
    " rgb(33, 150, 243)",
    "rgb(156, 39, 176)",
    "rgb(77, 182, 172)",
    "rgb(96, 125, 139)",
    "rgb(62, 39, 35)",
    "rgb(79, 195, 247)",
    "rgb(236, 64, 122)",
    "red",
    "#00fffc",
    "#00ff6c",
    "#e08bff",
  ];

  const [didTouchStage, setDidTouchStage] = useState(false);
  const checkDeselect = (e) => {
    const didTouchStage = e.target === e.target.getStage();
    setDidTouchStage(didTouchStage);
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const [selectedId, selectShape] = React.useState(null);

  const divRef = useRef(null);
  const [imagecap, setImagecap] = useState();

  const handleCapture = () => {
    selectShape("");
    toast.success("Saved!");
    setDidTouchStage(!didTouchStage);

    setTimeout(() => {
      if (divRef.current) {
        html2canvas(divRef.current).then((canvas) => {
          const imageUrl = canvas.toDataURL("image/png");

          setImagecap(imageUrl);
        });
        setStep(step + 1);
      }
    }, 1000);
  };

  const [isLoading, setIsLoading] = useState(true);

  const [backimg, setBackimg  ] = useState([]);
  const [frontimg, setFrontimg] = useState([]); // Initialize frontimg as an empty array

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
  
      const image = new Image();
      image.src = imageUrl;
  
      image.onload = () => {
        const maxWidth = 300; // Maximum width for the image
        const maxHeight = 300; // Maximum height for the image
        const originalWidth = image.width;
        const originalHeight = image.height;
  
        const aspectRatio = originalWidth / originalHeight;
  
        let newWidth = originalWidth;
        let newHeight = originalHeight;
  
        // Adjust width if needed
        if (originalWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;
        }
  
        // Adjust height if needed
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }
  
        // Create a new image object with dimensions and URL
        const newImage = {
          imageUrl,
          width: newWidth,
          height: newHeight,
        };
  
        // Update imageList with the new image object
        setImageList([...imageList, newImage]);
        setFrontimg((prevFrontimg) => [...prevFrontimg, imageUrl]); 
  
        setIsOpen2(false);
      };
    }
  };
  

  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

      const image = new Image();
      image.src = imageUrl;

      image.onload = () => {
        const maxWidth = 300; // Maximum width for the image
        const maxHeight = 300; // Maximum height for the image
        const originalWidth = image.width;
        const originalHeight = image.height;

        const aspectRatio = originalWidth / originalHeight;

        let newWidth = originalWidth;
        let newHeight = originalHeight;

    
        if (originalWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;
        }

  
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }

        const newImage = {
          imageUrl,
          width: newWidth,
          height: newHeight,
        };

    
        setImageList2([...imageList2, newImage]);
        setBackimg((prevBackimg) => [...prevBackimg, imageUrl]); 

        setIsOpen4(false);
      };
    }
  };

  const [imageList, setImageList] = useState([]);

  const [selectedFont, setSelectedFont] = useState("Montserrat");

  const handleFontChange = (font, selectedId) => {
    // const newFont = event.target.value;

    const newState = texts.map((obj) => {

      if (obj.id === selectedId) {
        return { ...obj, fontFamily: font };
      }

      return obj;
    });
    setTexts(newState);
  };

  const [fontcolor, setFontcolor] = useState("black");
  const selectcolor = (color, selectedId) => {
    // setFontcolor(color,);

    const newState = texts.map((obj) => {
  
      if (obj.id === selectedId) {
        return { ...obj, fill: color };
      }


      return obj;
    });

    setTexts(newState);
  };

  const [color, setColor] = useState("black");

  const handleColorChange1 = (event, selectedId) => {
    setColor(event.target.value);
    // selectShape(event.target.value)
    setFontcolor(event.target.value);

    const newState = texts.map((obj) => {
      // 👇️ if id equals 2, update country property
      if (obj.id === selectedId) {
        return { ...obj, fill: event.target.value };
      }

      // 👇️ otherwise return the object as is
      return obj;
    });

    setTexts(newState);
  };

  const handleDeleteImage = (index) => {
    const updatedImageList = imageList.filter((_, i) => i !== index);
    setImageList(updatedImageList);
  };

  const [texts, setTexts] = React.useState(initialTexts);

  const [newText, setNewText] = React.useState("");

  const addNewText = () => {
    if (newText.trim() !== "") {
      const newTextElement = {
        text: newText,
        x: 100,
        y: 100,
        fontSize: 30,
        draggable: true,
        fill: "black",
        fontFamily: "Montserrat",
        // width: 200,
        id: texts.length,
      };

      setTexts((prevTexts) => [...prevTexts, newTextElement]);
      setNewText(""); // Clear the input field after adding the text
    }
  };

  const deleteText = (id) => {
    setTexts((prevTexts) => prevTexts.filter((txt) => txt.id !== id));
    selectShape(null); // Deselect after deletion
  };

  const divRef2 = useRef(null);
  const [imagecap2, setImagecap2] = useState();

  const handleCapture2 = () => {
    selectShape("");
    setDidTouchStage(!didTouchStage);

    toast.success("Saved!");

    setTimeout(() => {
      if (divRef2.current) {
        html2canvas(divRef2.current).then((canvas) => {
          const imageUrl = canvas.toDataURL("image/png");

          setImagecap2(imageUrl);
        });
      }
      setStep(step + 1);
    }, 1000);
  };

  const [modalIsOpen4, setIsOpen4] = useState(false);

  const openmodal4 = () => {
    setIsOpen4(true);
  };

  const closeModal4 = () => {
    setIsOpen4(false);
  };

  const [imageList2, setImageList2] = useState([]);
  const [selectedFont2, setSelectedFont2] = useState("Montserrat");

  const handleFontChange2 = (font, selectedId) => {
    const newState = texts2.map((obj) => {
      if (obj.id === selectedId) {
        return { ...obj, fontFamily: font };
      }

      return obj;
    });
    setTexts2(newState);
  };

  const [fontcolor2, setFontcolor2] = useState("black");
  const selectcolor2 = (color, selectedId) => {
    // setFontcolor(color,);

    const newState = texts2.map((obj) => {
      if (obj.id === selectedId) {
        return { ...obj, fill: color };
      }

      return obj;
    });

    setTexts2(newState);
  };

  const [color2, setColor2] = useState("black");

  const handleColorChange2 = (event, selectedId) => {
    setColor2(event.target.value);
    // selectShape(event.target.value)
    setFontcolor2(event.target.value);

    const newState = texts2.map((obj) => {
      if (obj.id === selectedId) {
        return { ...obj, fill: event.target.value };
      }

      return obj;
    });

    setTexts2(newState);
  };

  const handleDeleteImage2 = (index) => {
    const updatedImageList = imageList2.filter((_, i) => i !== index);
    setImageList2(updatedImageList);
  };

  const [texts2, setTexts2] = React.useState(initialTexts);

  const [newText2, setNewText2] = React.useState("");
  const addNewText2 = () => {
    if (newText2.trim() !== "") {
      const newTextElement = {
        text: newText2,
        x: 100,
        y: 100,
        fontSize: 30,
        draggable: true,
        fill: "black",
        fontFamily: "Montserrat",
        // width: 200,
        id: texts2.length,
      };

      setTexts2((prevTexts) => [...prevTexts, newTextElement]);
      setNewText2(""); // Clear the input field after adding the text
    }
  };

  const deleteText2 = (id) => {
    setTexts2((prevTexts) => prevTexts.filter((txt) => txt.id !== id));
    selectShape(null);
  };

  const tshirtsizes = ["S", "M", "L", "XL", "2XL", "3XL"];

  const dispatch = useDispatch();

  const addItemToBasket = () => {
    dispatch(
      addToBasket({
        id,
        price,
        tcolor,
        selectsize,
        imagecap,
        imageList:frontimg,
        imageList2:backimg,

        imagecap2,
      })
    );
    toast.success("Add to Bag");
    navigate("/Cart");
  };

  const [tColors, setTcolors] = useState([]);

  useEffect(() => {
    const todosref = collection(db, "Products", "tShirts", "colors");
    const q = query(todosref, orderBy("num", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setTcolors(cities);

      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [id, setId] = useState();

  const selectid = (id) => {
    setId(id);
  };

  const [price, setPrice] = useState();
  const selectPrice = (price) => {
    setPrice(price);
  };
  const [tcolor, setTcolor] = useState();
  const selecttcolor = (color) => {
    setTcolor(color);
  };

  const handleCombinedClick = (item) => {
    selecttcolor(item.color);
    selectPrice(item.Price);
    selectid(item.id);
  };

  const [stageDimensions, setStageDimensions] = useState({
    width: 300,
    height: 500,
  });

  // const checkDeselect = () => {
  //   // Your checkDeselect logic here
  // };

  useEffect(() => {
    const updateDimensions = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth <= 500) {
        setStageDimensions({ width: 250, height: 400 });
      } else {
        setStageDimensions({ width: 300, height: 500 });
      }
    };

    // Call the function once on component mount
    updateDimensions();

    // Add event listener to update dimensions when window is resized
    window.addEventListener("resize", updateDimensions);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  if (isLoading) {
    return (
      <>
        <>
          <Navbar />

          <div className="p-d1 tab-content">
            <div className="p-d2">
              <div className="p-d3">
                <div className="p-s-section">
                  <div className="">
                    <div class=" step-w1">
                      <div className="step-si">
                        <span className="size-step3"></span>
                        <div className="size-step4"></div>
                      </div>
                      <div className="step-si">
                        <span className="size-step3"></span>
                        <div className="size-step4"></div>
                      </div>
                      <div className="step-si">
                        <span className="size-step3"></span>
                        <div className="size-step4"></div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ margin: "10px 0px" }} />
                  <div style={{ display: "flex" }}>
                    <div className="size-step4"></div>
                  </div>

                  <div className="step-w2">
                    <div className="size-step2"></div>
                    <div className="size-step2"></div>
                    <div className="size-step2"></div>
                    <div className="size-step2"></div>
                    <div className="size-step2"></div>

                    <div className="size-step2"></div>
                    <div className="size-step2"></div>
                    <div className="size-step2"></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      margin: "10px 0px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="size-step4"></div>
                    <div className="size-step4"></div>
                  </div>
                  <div class=" step-w3">
                    <div className="size-step"></div>
                    <div className="size-step"></div>
                    <div className="size-step"></div>
                    <div className="size-step"></div>
                    <div className="size-step"></div>
                  </div>
                  <div class=" step-w6">
                    <div className="size-step6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }

  return (
    <>
      <Navbar />

      <div className="p-d1 tab-content">
        <div className="p-d2">
          <div className="p-d3">
            <div className="p-s-section">
              <div className="progress-steps">
                <div class="stepper-wrapper">
                  <div class="stepper-item completed">
                    <div class="step-counter">
                      {step > 1 ? (
                        <AiFillCheckCircle
                          style={{ cursor: "pointer" }}
                          onClick={handlePrevStep}
                          color="#FF9417"
                          size={30}
                        />
                      ) : (
                        1
                      )}
                    </div>
                    <div class="step-name">
                      <h1 className="steps-f-size">Pick Color & Size</h1>
                    </div>
                  </div>
                  <div class="stepper-item completed">
                    <div class="step-counter">
                      {step > 3 ? (
                        <AiFillCheckCircle
                          style={{ cursor: "pointer" }}
                          onClick={handlePrevStep}
                          color="#FF9417"
                          size={30}
                        />
                      ) : (
                        2
                      )}
                    </div>
                    <div class="step-name">
                      <h1 className="steps-f-size">Finalise Design</h1>
                    </div>
                  </div>
                  <div class="stepper-item active">
                    <div class="step-counter">3</div>
                    <div class="step-name">
                      <h1 className="steps-f-size">Preview</h1>
                    </div>
                  </div>
                </div>
              </div>
              {step === 1 && (
                <div>
                  <div className="select-color">
                    <div className="select-c-d1"></div>
                    <h1 className="head-s-c-1">Select Color </h1>
                    <div>
                      <div className="d-f-sec">
                        {Array.isArray(tColors)
                          ? tColors.map((item, key) => {
                              return (
                                <>
                                  <div
                                    onClick={() => handleCombinedClick(item)}
                                  >
                                    <div
                                      className={
                                        id === item.id ? "selected-c" : "rel"
                                      }
                                    >
                                      {id === item.id ? (
                                        <AiFillCheckCircle
                                          className="select-color-icon"
                                          color="#ff9417"
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      <div
                                        style={{ backgroundColor: item.color }}
                                        className={"box-color-select1"}
                                      ></div>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="select-size">
                    <div className="sel-d1">
                      <h1 className="select-sizh1">Select Size </h1>
                      <h1 onClick={openModal} className="select-Guide">
                        Size Guide
                      </h1>
                    </div>
                    <div className="size-sec">
                      {tshirtsizes.map((item) => (
                        <>
                          <div
                            className={
                              selectsize === item ? "size-boxs" : "size-box"
                            }
                            onClick={() => sizeselected(item)}
                          >
                            <span className="span-size-s">{item}</span>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="btn-next-pro  ">
                    {tcolor ? (
                      <>
                        <>
                          {selectsize ? (
                            <>
                              <button
                                onClick={handleNextStep}
                                className="btn-next-step2"
                              >
                                NEXT
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => toast.error("Select Size")}
                                className="btn-next-step2"
                              >
                                NEXT
                              </button>
                            </>
                          )}
                        </>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => toast.error("Select Color")}
                          className="btn-next-step2"
                        >
                          NEXT
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="step-2-c1">
                  {step > 1 && (
                    <div className="step-2-content">
                      <div className="st2-c">
                        <div className="st2-d2">
                          <div className="tshirt-customizer" ref={divRef}>
                            <div className="section-resize-img-p">
                              {/* {fronturl.map((imageUrl) => (
                                <>
                                  <img
                                    style={{ width: "50px", height: "50px" }}
                                    src={imageUrl}
                                    alt=""
                                  />
                                </>
                              ))} */}

                              <div className="t4">
                                <Stage
                                  width={stageDimensions.width}
                                  height={stageDimensions.height}
                                  className="stage-img-c"
                                  // width={window.innerWidth}
                                  // height={window.innerHeight}
                                  onMouseDown={checkDeselect}
                                  onTouchStart={checkDeselect}
                                >
                                  <Layer>
                                    {imageList.map((imageInfo, index) => (
                                      <ImageTransformer
                                        key={index}
                                        index={index}
                                        handleDelete={handleDeleteImage}
                                        didTouchStage={didTouchStage}
                                        url={imageInfo.imageUrl}
                                        width={imageInfo.width}
                                        height={imageInfo.height}
                                        // width={100}
                                        // height={100}
                                        x={10}
                                        y={30}
                                      />
                                    ))}

                                    <div className="text-layer">
                                      {texts.map((txt, i) => (
                                        <React.Fragment key={i}>
                                          <TheText
                                            textProps={txt}
                                            isSelected={txt.id === selectedId}
                                            onSelect={() => {
                                              selectShape(txt.id);
                                            }}
                                            onChange={(newAttrs) => {
                                              const txts = texts.slice();
                                              txts[i] = newAttrs;
                                              setTexts(txts);
                                            }}
                                            color={fontcolor}
                                            fontFamily={selectedFont}
                                            onDelete={() => deleteText(txt.id)}
                                          />
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </Layer>
                                </Stage>
                              </div>
                              <img
                                className="back-image"
                                src={require("../../assets/img/img-front.webp")}
                                alt="Back"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tsection ? (
                    <>
                      <div className="btn-next-pro">
                        <div className="close-t-section">
                          <AiOutlineClose
                            onClick={() => setTsection(!tsection)}
                            color="black"
                            size={25}
                          />
                        </div>

                        <div className="t-section-content">
                          <div style={{ padding: "10px" }}>
                            {activeTab === "tab1" && (
                              <>
                                <div className="form-content-step-2">
                                  <input
                                    type="text"
                                    value={newText}
                                    onChange={(e) => setNewText(e.target.value)}
                                    placeholder="Tap to enter Text"
                                    className="add-text-f"
                                  />
                                  <button
                                    onClick={addNewText}
                                    className="btn-add-t"
                                  >
                                    <BsCheck2 size={20} />
                                  </button>
                                </div>
                                <p className="max-t">Max. 60 Characters</p>
                              </>
                            )}
                            {activeTab === "tab2" && (
                              <>
                                <div className="content-fonts-step2">
                                  {fontOptions.map((font) => (
                                    // <option key={font} value={font}>
                                    //   {font}
                                    // </option>
                                    <>
                                      <div className="p-r-step2">
                                        <button
                                          className="btn-add-font"
                                          onClick={() =>
                                            handleFontChange(font, selectedId)
                                          }
                                        >
                                          <span
                                            className="font-ss-f"
                                            style={{ fontFamily: font }}
                                          >
                                            Abc
                                          </span>
                                          <span className="ss-22ss2">
                                            {font}
                                          </span>
                                        </button>
                                        {/* {selectedFont === font ? (
                                          <>
                                            <div className="svg-chek-step2">
                                              <BsFillCheckCircleFill />
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )} */}
                                      </div>
                                    </>
                                  ))}
                                </div>

                                <p style={{ fontFamily: selectedFont }}>
                                  hello
                                </p>
                              </>
                            )}
                            {activeTab === "tab3" && (
                              <>
                                <div className="btn-main--d1">
                                  {fontscolor.map((color) => (
                                    <>
                                      <div className="font-colors-s">
                                        <button
                                          style={{ background: color }}
                                          className="btn-select-color1"
                                          onClick={() =>
                                            selectcolor(color, selectedId)
                                          }
                                        ></button>
                                        {/* {texts?.fill === fontcolor ? (
                                          <>
                                            <div className="svg-chek-step2">
                                              <BsFillCheckCircleFill
                                                color="#ff9417"
                                                size={20}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )} */}
                                      </div>
                                    </>
                                  ))}
                                </div>
                                <div className="d-f-cp">
                                  <label htmlFor="colorPicker">
                                    More Colors :
                                  </label>
                                  <input
                                    type="color"
                                    id="colorPicker"
                                    value={color}
                                    onChange={(event) =>
                                      handleColorChange1(event, selectedId)
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <div className="tab-container">
                            <div className="tab-buttons">
                              <button
                                className={activeTab === "tab1" ? "active" : ""}
                                onClick={() => handleTabClick("tab1")}
                              >
                                <span>
                                  <BiText size={20} />
                                </span>

                                <span>Edit</span>
                              </button>
                              {texts.length === 0 ? (
                                <>
                                  <button
                                    onClick={() =>
                                      toast.error("Add or Select Text First")
                                    }
                                    className={
                                      activeTab === "tab2" ? "active" : ""
                                    }
                                  >
                                    <span>
                                      <RiFontSize size={20} color="gray" />
                                    </span>
                                    <span>Font</span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className={
                                      activeTab === "tab2" ? "active" : ""
                                    }
                                    onClick={() => handleTabClick("tab2")}
                                  >
                                    <span>
                                      <RiFontSize size={20} />
                                    </span>
                                    <span>Font</span>
                                  </button>
                                </>
                              )}

                              {texts.length === 0 ? (
                                <>
                                  <button
                                    onClick={() =>
                                      toast.error("Add or Select Text First")
                                    }
                                    className={
                                      activeTab === "tab2" ? "active" : ""
                                    }
                                  >
                                    <span>
                                      <IoIosColorPalette
                                        size={20}
                                        color="gray"
                                      />
                                    </span>
                                    <span>Color</span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className={
                                      activeTab === "tab3" ? "active" : ""
                                    }
                                    onClick={() => handleTabClick("tab3")}
                                  >
                                    <span>
                                      <IoIosColorPalette size={20} />
                                    </span>
                                    <span>Color</span>
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="btn-next-pro">
                        <div className="step-2-btns">
                          <button
                            className="btn-text-step2"
                            onClick={() => setTsection(!tsection)}
                          >
                            <TbTextSize size={20} />
                            Add Text
                          </button>
                          <button
                            className="btn-gallery-step2"
                            onClick={openmodal2}
                          >
                            <BsImage size={20} />
                            Gallery
                          </button>
                        </div>
                        <div className="step-2-btns">
                          <button
                            className="btn-next-step2"
                            onClick={handleCapture}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {step === 3 && (
                <div className="step-2-c1">
                  {step > 1 && (
                    <div className="step-2-content">
                      <div className="st2-c">
                        <div className="st2-d2">
                          <div className="tshirt-customizer" ref={divRef2}>
                            <div className="section-resize-img-p">
                              <div className="t4">
                                <Stage
                                  width={stageDimensions.width}
                                  height={stageDimensions.height}
                                  className="stage-img-c"
                                  // width={window.innerWidth}
                                  // height={window.innerHeight}
                                  onMouseDown={checkDeselect}
                                  onTouchStart={checkDeselect}
                                >
                                  <Layer>
                                    {imageList2.map((imageInfo, index) => (
                                      <ImageTransformer
                                        index={index}
                                        handleDelete={handleDeleteImage2}
                                        didTouchStage={didTouchStage}
                                        url={imageInfo.imageUrl}
                                        width={imageInfo.width}
                                        height={imageInfo.height}
                                        x={10}
                                        y={30}
                                      />
                                    ))}

                                    <div className="text-layer">
                                      {texts2.map((txt, i) => (
                                        <React.Fragment key={i}>
                                          <TheText
                                            textProps={txt}
                                            isSelected={txt.id === selectedId}
                                            onSelect={() => {
                                              selectShape(txt.id);
                                            }}
                                            onChange={(newAttrs) => {
                                              const txts = texts.slice();
                                              txts[i] = newAttrs;
                                              setTexts2(txts);
                                            }}
                                            color={fontcolor2}
                                            fontFamily={selectedFont2}
                                            onDelete={() => deleteText2(txt.id)}
                                          />
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </Layer>
                                </Stage>
                              </div>
                              <img
                                className="back-image"
                                src={require("../../assets/img/img-back.webp")}
                                alt="Back"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tsection ? (
                    <>
                      <div className="btn-next-pro">
                        <div className="close-t-section">
                          <AiOutlineClose
                            onClick={() => setTsection(!tsection)}
                            color="black"
                            size={25}
                          />
                        </div>

                        <div className="t-section-content">
                          <div style={{ padding: "10px" }}>
                            {activeTab === "tab1" && (
                              <>
                                <div className="form-content-step-2">
                                  <input
                                    type="text"
                                    value={newText2}
                                    onChange={(e) =>
                                      setNewText2(e.target.value)
                                    }
                                    placeholder="Tap to enter Text"
                                    className="add-text-f"
                                  />
                                  <button
                                    onClick={addNewText2}
                                    className="btn-add-t"
                                  >
                                    <BsCheck2 size={20} />
                                  </button>
                                </div>
                                <p className="max-t">Max. 60 Characters</p>
                              </>
                            )}
                            {activeTab === "tab2" && (
                              <>
                                <div className="content-fonts-step2">
                                  {fontOptions.map((font) => (
                                    // <option key={font} value={font}>
                                    //   {font}
                                    // </option>
                                    <>
                                      <div className="p-r-step2">
                                        <button
                                          className="btn-add-font"
                                          onClick={() =>
                                            handleFontChange2(font, selectedId)
                                          }
                                        >
                                          <span
                                            className="font-ss-f"
                                            style={{ fontFamily: font }}
                                          >
                                            Abc
                                          </span>
                                          <span className="ss-22ss2">
                                            {font}
                                          </span>
                                        </button>
                                        {selectedFont === font ? (
                                          <>
                                            <div className="svg-chek-step2">
                                              <BsFillCheckCircleFill />
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </>
                                  ))}
                                </div>

                                <p style={{ fontFamily: selectedFont }}>
                                  hello
                                </p>
                              </>
                            )}
                            {activeTab === "tab3" && (
                              <>
                                <div className="btn-main--d1">
                                  {fontscolor.map((color) => (
                                    <>
                                      <div className="font-colors-s">
                                        <button
                                          style={{ background: color }}
                                          className="btn-select-color1"
                                          onClick={() =>
                                            selectcolor2(color, selectedId)
                                          }
                                        ></button>
                                        {fontcolor === color ? (
                                          <>
                                            <div className="svg-chek-step2">
                                              <BsFillCheckCircleFill
                                                color="#ff9417"
                                                size={20}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </>
                                  ))}
                                </div>
                                <div className="d-f-cp">
                                  <label htmlFor="colorPicker">
                                    More Colors :
                                  </label>
                                  <input
                                    type="color"
                                    id="colorPicker"
                                    value={color2}
                                    onChange={(event) =>
                                      handleColorChange2(event, selectedId)
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <div className="tab-container">
                            <div className="tab-buttons">
                              <button
                                className={activeTab === "tab1" ? "active" : ""}
                                onClick={() => handleTabClick("tab1")}
                              >
                                <span>
                                  <BiText size={20} />
                                </span>

                                <span>Edit</span>
                              </button>
                              {texts2.length === 0 ? (
                                <>
                                  <button
                                    className={
                                      activeTab === "tab2" ? "active" : ""
                                    }
                                  >
                                    <span>
                                      <RiFontSize size={20} color="gray" />
                                    </span>
                                    <span>Font</span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className={
                                      activeTab === "tab2" ? "active" : ""
                                    }
                                    onClick={() => handleTabClick("tab2")}
                                  >
                                    <span>
                                      <RiFontSize size={20} />
                                    </span>
                                    <span>Font</span>
                                  </button>
                                </>
                              )}

                              {texts2.length === 0 ? (
                                <>
                                  <button
                                    className={
                                      activeTab === "tab2" ? "active" : ""
                                    }
                                  >
                                    <span>
                                      <IoIosColorPalette
                                        size={20}
                                        color="gray"
                                      />
                                    </span>
                                    <span>Color</span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className={
                                      activeTab === "tab3" ? "active" : ""
                                    }
                                    onClick={() => handleTabClick("tab3")}
                                  >
                                    <span>
                                      <IoIosColorPalette size={20} />
                                    </span>
                                    <span>Color</span>
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="btn-next-pro">
                        <div className="step-2-btns">
                          <button
                            className="btn-text-step2"
                            onClick={() => setTsection(!tsection)}
                          >
                            <TbTextSize size={20} />
                            Add Text
                          </button>
                          <button
                            className="btn-gallery-step2"
                            onClick={openmodal4}
                          >
                            <BsImage size={20} />
                            Gallery
                          </button>
                        </div>
                        <div className="step-2-btns">
                          <button
                            className="btn-next-step2"
                            onClick={handleCapture2}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {step === 4 && (
                <div className="step-2-c1">
                  {step > 1 && (
                    <div className="step-2-content">
                      <div className="st2-c">
                        <div className="st2-d2">
                          <div className="flip-icon" onClick={handleFlip}>
                            <MdFlipCameraAndroid size={30} className="" />
                            <span
                              className="span-flip-i"
                              style={{ fontSize: "12px" }}
                            >
                              Flip
                            </span>
                          </div>
                          {isFlipped ? (
                            <>
                              <img
                                className="back-image"
                                src={imagecap2}
                                alt="Back"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                className="front-image"
                                src={imagecap}
                                alt="Front"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="btn-next-pro">
                    <div className="step-2-btns">
                      <button
                        onClick={addItemToBasket}
                        className="btn-next-step2"
                      >
                        ADD TO BAG
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen2}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-step-head">
            <div className="close-m1">
              <AiOutlineClose onClick={closeModal2} color="black" size={25} />
            </div>
            <div className="step-2-m1">
              <div className="modal-step-2content">
                <BsImage size={30} />
                <div className="para-modal-step2">
                  <p className="para-1-step2">
                    Upload an image to place it on t-shirt
                  </p>
                </div>
              </div>
              <div className="modal-set-1">
                <input
                  accept="image/*"
                  onChange={handleImageChange}
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                />
                <label class="custom-file-label" for="customFile">
                  Choose File
                </label>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={modalIsOpen4}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal4}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-step-head">
            <div className="close-m1">
              <AiOutlineClose onClick={closeModal4} color="black" size={25} />
            </div>
            <div className="step-2-m1">
              <div className="modal-step-2content">
                <BsImage size={30} />
                <div className="para-modal-step2">
                  <p className="para-1-step2">
                    Upload an image to place it on t-shirt
                  </p>
                </div>
              </div>
              <div className="modal-set-1">
                <input
                  accept="image/*"
                  onChange={handleImageChange2}
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                />
                <label class="custom-file-label" for="customFile">
                  Choose File
                </label>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-pro1">
            <div className="close-m1">
              <AiOutlineClose onClick={closeModal} color="black" size={25} />
            </div>
            <div className="modal-content-s">
              <h1 className="size-g-h1">SIZE GUIDE</h1>

              <div>
                <img
                  className="img-size-guide"
                  src={require("../../assets/img/sizeinch.webp")}
                  alt=""
                />
              </div>
              <table className="table-modal-size-guid">
                <thead>
                  <tr className="tr-table">
                    <th>Size</th>
                    <th>Chest (In Inch)</th>
                    <th>Front Length (In Inch)</th>
                    <th>Sleeve Length (In Inch)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="tr-table">
                    <td>s</td>
                    <td>38.0</td>
                    <td>27.25</td>
                    <td>8.0</td>
                  </tr>
                  <tr className="tr-table">
                    <td>s</td>
                    <td>38.0</td>
                    <td>27.25</td>
                    <td>8.0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Product;
